import i18n from '@/i18n';
import { BvTableField } from 'bootstrap-vue';

const columns: Array<{ key: string } & BvTableField> = [
  {
    label: i18n.t('colunas.moskit') as string,
    key: 'id_moskit',
    sortable: true,
    thStyle: { width: '120px' },
    stickyColumn: true,
  },
  {
    label: i18n.t('colunas.id') as string,
    key: 'id',
    sortable: true,
    thStyle: { width: '80px' },
    stickyColumn: true,
  },  {
    label: i18n.tc('colunas.nome.vendedor', 1) as string,
    key: 'vendedores.id',
    sortable: true,
    thStyle: { width: '200px' },
  },
  {
    label: i18n.t('colunas.placa') as string,
    key: 'moskit.placa',
    sortable: true,
    thStyle: { width: '80px' },
  },
  {
    label: i18n.tc('colunas.nome.negocio', 2) as string,
    key: 'moskit.nome_negocio',
    sortable: true,
    thStyle: { width: '200px' },
  },
  {
    label: i18n.tc('colunas.nome.fatura', 2) as string,
    key: 'nome',
    sortable: false,
    thStyle: { width: '200px' },
  },
  {
    label: i18n.tc('colunas.valor.protecao', 2) as string,
    key: 'moskit.valor_protecao',
    sortable: true,
    thStyle: { width: '120px' },
    thClass: 'text-center',
  },
  {
    label: i18n.tc('colunas.valor.mensal', 2) as string,
    key: 'moskit.valor_mensal',
    sortable: true,
    thStyle: { width: '120px' },
    thClass: 'text-center',
  },
  {
    label: i18n.tc('colunas.valor.fatura', 2) as string,
    key: 'valor',
    sortable: true,
    thStyle: { width: '120px' },
    thClass: 'text-center',
  },
  {
    label: i18n.t('colunas.tipo') as string,
    key: 'tipo',
    sortable: true,
    thStyle: { width: '120px' },
  },
  {
    label: i18n.t('colunas.situacao') as string,
    key: 'situacao',
    sortable: true,
    thStyle: { width: '90px' },
  },
  {
    label: i18n.tc('colunas.data.criacao', 2) as string,
    key: 'data_criacao',
    sortable: true,
    thStyle: { width: '100px' },
    thClass: 'text-center',
  },
  {
    label: i18n.tc('colunas.data.vencimento', 2) as string,
    key: 'data_vencimento',
    sortable: true,
    thStyle: { width: '100px' },
    thClass: 'text-center',
  },
  {
    label: i18n.tc('colunas.data.pagamento', 2) as string,
    key: 'data_pagamento',
    sortable: true,
    thStyle: { width: '100px' },
    thClass: 'text-center',
  },
  {
    label: i18n.tc('colunas.data.cadastro', 2) as string,
    key: 'moskit.cadastro_data',
    sortable: true,
    thStyle: { width: '100px' },
    thClass: 'text-center',
  },
];

const situacoes: ISelectSituacao[] = [
  {
    text: i18n.t('faturas.situacoes.todos') as string,
    value: '',
    variant: '',
  },
  {
    text: i18n.t('faturas.situacoes.pendente') as string,
    value: 'pending',
    variant: 'secondary',
  },
  {
    text: i18n.t('faturas.situacoes.pago') as string,
    value: 'paid',
    variant: 'success',
  },
  {
    text: i18n.t('faturas.situacoes.cancelado') as string,
    value: 'canceled',
    variant: 'warning',
  },
  {
    text: i18n.t('faturas.situacoes.vencido') as string,
    value: 'expired',
    variant: 'danger',
  },
];

const columnsFilter: IFiltroField[] = [
  {
    label: i18n.t('colunas.moskit') as string,
    id: 'iugu_faturas.id_moskit',
    type: 'id',
  },
  {
    label: i18n.tc('colunas.nome.vendedor', 1) as string,
    id: 'vendedores.nome',
    type: 'text',
  },
  {
    label: i18n.t('colunas.placa') as string,
    id: 'moskit.placa',
    type: 'id',
  },
  {
    label: i18n.tc('colunas.nome.negocio', 2) as string,
    id: 'moskit.nome_negocio',
    type: 'text',
  },
  {
    label: i18n.tc('colunas.nome.fatura', 2) as string,
    id: 'iugu_faturas.nome',
    type: 'text',
  },
  {
    label: i18n.tc('colunas.valor.protecao') as string,
    id: 'moskit.valor_protecao',
    type: 'number',
  },
  {
    label: i18n.tc('colunas.valor.fatura', 2) as string,
    id: 'iugu_faturas.valor',
    type: 'number',
  },
  {
    label: i18n.t('colunas.tipo') as string,
    id: 'iugu_faturas.tipo',
    type: 'enum',
    enum: [
      { value: 'boleto1', label: i18n.t('faturas.metodo.entrada') as string },
      { value: 'boleto2', label: i18n.t('faturas.metodo.parcela') as string },
      { value: 'cartao', label: i18n.t('faturas.metodo.cartao') as string },
    ],
  },
  {
    label: i18n.t('colunas.situacao') as string,
    id: 'iugu_faturas.situacao',
    type: 'enum',
    enum: situacoes.map<IFiltroEnum>((item) => {
      return { value: item.value, label: item.text };
    }),
  },
  {
    label: i18n.tc('colunas.data.criacao', 2) as string,
    id: 'iugu_faturas.data_criacao',
    type: 'date',
  },
  {
    label: i18n.tc('colunas.data.vencimento', 2) as string,
    id: 'iugu_faturas.data_vencimento',
    type: 'date',
  },
  {
    label: i18n.tc('colunas.data.pagamento', 2) as string,
    id: 'iugu_faturas.data_pagamento',
    type: 'date',
  },
  {
    label: i18n.tc('colunas.data.cadastro', 2) as string,
    id: 'moskit.cadastro_data',
    type: 'date',
  },
];

export { columns, situacoes, columnsFilter };
