

































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Faturas } from '@/plugins/api';
import { BvTableCtxObject } from 'bootstrap-vue';
import { columns } from './components/tabela';
import * as dot from 'dot-wild';

@Component
export default class InfoFaturas extends Vue {
  public loadingMoskit = true;
  public moskitId!: number;
  public moskit: IDeal = {} as IDeal;

  public loadingPagamentos = true;
  public currentPage = 1;
  public totalRows = 1;
  public perPage = 50;
  public sortBy = 'time';
  public sortDesc = true;

  public columns = columns;
  public soma = {} as IFatura;
  public getField = dot.get;

  get vendedorNome(): string {
    return this.moskit?.responsible?.name ?? '';
  }

  get vendedorPicture(): string {
    return this.moskit?.responsible?.picture ?? '';
  }

  created(): void {
    this.moskitId = parseInt(this.$route.params.id, 10);
    this.updateInfo();
  }

  updateInfo(): void {
    // TODO readicionar isso
    // this.loadingMoskit = true;
    // Faturas.getMoskit(this.moskitId).then((response: GetMoskitResponse) => {
    //   this.loadingMoskit = false;
    //   this.moskit = response.data;
    // });
  }

  provider(
    context: BvTableCtxObject,
    callback: (data: IFatura[]) => void
  ): void {
    this.loadingPagamentos = true;

    Faturas.getByID(this.moskitId)
      .then((result) => {
        const faturamentos = result.data;

        this.soma = faturamentos.reduce((result, item) => {
          const current = JSON.parse(JSON.stringify(result ?? {})) as IFatura;

          current.valor =
            parseFloat(current.valor.toString() ?? '0') +
            parseFloat(item.valor.toString() ?? '0');
          current.moskit.valor_protecao =
            parseFloat(current.moskit.valor_protecao.toString() ?? '0') +
            parseFloat(item.moskit.valor_protecao.toString() ?? '0');
          current.moskit.valor_adesao =
            parseFloat(current.moskit.valor_adesao.toString() ?? '0') +
            parseFloat(item.moskit.valor_adesao.toString() ?? '0');

          return current;
        }, {valor: 0, moskit: {valor_protecao: 0, valor_adesao: 0}} as IFatura);

        callback(faturamentos);
      })
      .catch(() => {
        callback([]);
      })
      .finally(() => {
        this.loadingPagamentos = false;
      });
  }

  findCustomField(fieldId: number): string {
    const field =
      this.moskit?.customFieldValues?.find(
        (field) => field.customField.id === fieldId
      ) ?? ({} as ICustomFieldValue);

    return field.label ?? field.value;
  }

  getValorAdesaoClass(adesao: number, mensal: number|null): string {
    if (mensal === null) {
      return '';
    }

    if (Number(adesao) < Number(mensal)) {
      return 'text-danger';
    }

    return '';
  }
}
